import { nextTick } from "@vue/runtime-core";
import { XoneDataObject } from "./appData/core/XoneDataObject";
import AppDataHandler from "./AppDataHandler";
import { getView } from "./XoneViewsHandler";
import xoneAppHandler from "./XoneAppHandler";
import { generateUniqueId } from "./helperFunctions/StringHelper";
import { getAppPath } from "./helperFunctions/ImageHelper";

// /**
//  * _entryPointFake
//  * @type {string}
//  */
// let _entryPointFake;

// export const setEntryPointFake = (/** @type {string} */ value) => (_entryPointFake = value);

/**
 * _doLogin
 * @type {function():Promise<void>}
 */
let _doLogin;

/**
 * setDoLogin
 */
export const setDoLogin = (/** @type {function():Promise<void>} */ callback) => {
	_doLogin = callback;
};

/**
 * _openUrlCallback
 * @type {function}
 */
let _openUrlCallback;

/**
 * _msgBoxCallback
 * @type {function}
 */
let _msgBoxCallback;

/**
 * _customMsgBoxCallback
 * @type {function}
 */
let _customMsgBoxCallback;

/**
 * _hideLoaderCallback
 * @type {function}
 */
let _hideLoaderCallback;

/**
 * _showGroupCallback
 * @type {function}
 */
let _showGroupCallback;

/**
 * _showLoaderCallback
 * @type {function}
 */
let _showLoaderCallback;

/**
 * _showToastCallback
 * @type {function}
 */
let _showToastCallback;

/**
 * _showSnackbarCallback
 * @type {function}
 */
let _showSnackbarCallback;

/**
 * _startCameraCallback
 * @type {function}
 */
let _startCameraCallback;

/**
 * _xoneDataObject
 * @type {XoneDataObject}
 */
let _xoneDataObject;

/**
 * Add callback function showSnackbar from SnackBar component
 * @param {function} openUrlCallback
 */
export const setOpenUrlCallback = (openUrlCallback) => {
	_openUrlCallback = openUrlCallback;
};

/**
 * Add callback function msgBox from current msgbox component
 * @param {function} msgBoxCallback
 */
export const setMsgBoxCallback = (msgBoxCallback) => {
	_msgBoxCallback = msgBoxCallback;
};

/**
 * Add callback function msgBox from current msgbox component
 * @param {function} customMsgBoxCallback
 */
export const setCustomMsgBoxCallback = (customMsgBoxCallback) => {
	_customMsgBoxCallback = customMsgBoxCallback;
};

/**
 * Add callback function hideLoader from current loader component
 * @param {function} hideLoaderCallback
 */
export const setHideLoaderCallback = (hideLoaderCallback) => {
	_hideLoaderCallback = hideLoaderCallback;
};

/**
 * Add callback function showGroup from current coll component
 * @param {function} showGroupCallback
 */
export const setShowGroupCallback = (showGroupCallback) => {
	_showGroupCallback = showGroupCallback;
};

/**
 * Add callback function showLoader from Loader component
 * @param {function} showLoaderCallback
 */
export const setShowLoaderCallback = (showLoaderCallback) => {
	_showLoaderCallback = showLoaderCallback;
};

/**
 * Add callback function showToast from Toast component
 * @param {function} showToastCallback
 */
export const setShowToastCallback = (showToastCallback) => {
	_showToastCallback = showToastCallback;
};

/**
 * Add callback function showSnackbar from SnackBar component
 * @param {function} showSnackbarCallback
 */
export const setShowSnackbarCallback = (showSnackbarCallback) => {
	_showSnackbarCallback = showSnackbarCallback;
};

/**
 * Add callback function showSnackbar from SnackBar component
 * @param {function} startCameraCallback
 */
export const setStartCameraCallback = (startCameraCallback) => {
	_startCameraCallback = startCameraCallback;
};

/**
 * setXoneDataObject
 * @param {XoneDataObject} xoneDataObject
 */
export const setXoneDataObject = (xoneDataObject) => {
	_xoneDataObject = xoneDataObject;
};

/**
 * @type {Object}
 */
let _lastGpsPosition;

/**
 * @type {Map<string, Notification>}
 */
const _mapNotifications = new Map();

/**
 * Types Definitions
 *
 * @typedef {object} SnackBarParams
 * @property {string} [color]
 * @property {'short'|'long'|'indeterminate'|'indefinite'} [duration]
 * @property {string} [width]
 * @property {string} [height]
 * @property {string} [text]
 * @property {string} [textColor]
 * @property {string} [actionText]
 * @property {string} [actionTextColor]
 * @property {Function} [actionMethod]
 */

/**
 * XoneUI class
 */
class XoneUI {
	/**
	 * @type {XoneUI}
	 */
	static _instance;

	constructor() {
		if (XoneUI._instance) return XoneUI._instance;
		XoneUI._instance = this;
	}

	addCalendarItem(title, description, location, dtStart, dtEnd) {}
	askUserForGPSPermission() {} // Ignorar
	canMakePhoneCall() {} // Ignorar
	captureImage(PropName, CaptureObjectName) {}
	checkGPSStatus() {}

	/**
	 * clearDrawing
	 * @param {string} PropName
	 */
	clearDrawing(PropName) {
		// xoneView
		const xoneView = getView(_xoneDataObject);
		if (!xoneView) return;

		xoneView[PropName]?.clearDrawing();
	}

	createShortcut() {} // Ignorar
	deleteShortcut() {} // Ignorar

	/**
	 * dismissNotification
	 * @param {string} id
	 */
	dismissNotification(id) {
		id = id.toString();

		if (!_mapNotifications.has(id)) return;

		const notification = _mapNotifications.get(id);
		notification.close();

		_mapNotifications.delete(id);
	}

	drawMapRoute(PropName, DestinationLatitude, DestinationLongitude, SourceLatitude, SourceLongitude, Mode, StrokeColor) {}
	endPrint() {}
	ensureVisible(Prop, type) {}

	/**
	 * executeMethodAfterDelay
	 * @param {string} NodeName
	 * @param {number} [delay]
	 */
	executeActionAfterDelay(NodeName, delay = 1) {
		this.executeMethodAfterDelay(NodeName, delay);
	}

	/**
	 * executeMethodAfterDelay
	 * @param {string} NodeName
	 * @param {number} [delay]
	 */
	executeMethodAfterDelay(NodeName, delay = 1) {
		setTimeout(() => _xoneDataObject?.executeNode(NodeName), delay * 1000);
	}

	/**
	 * Go to previus object in stack
	 */
	exit() {
		AppDataHandler.deletelastBreadcrumb();
		history.back();
	}

	/**
	 * Exit  App
	 */
	exitApp() {
		AppDataHandler.clearBreadcrumbs();
		history.back();
		_doLogin();
	}

	/**
	 * getLastKnownLocation
	 */
	getLastKnownLocation() {
		const { latitude, longitude } = _lastGpsPosition?.coords;
		return { latitude, longitude };
	}

	/**
	 * getLastKnownLocationAccuracy
	 */
	getLastKnownLocationAccuracy() {
		const { accuracy } = _lastGpsPosition?.coords;
		return accuracy;
	}

	/**
	 * getLastKnownLocationAltitude
	 */
	getLastKnownLocationAltitude() {
		const { altitude } = _lastGpsPosition?.coords;
		return altitude;
	}

	/**
	 * getLastKnownLocationBearing
	 */
	getLastKnownLocationBearing() {
		const { heading } = _lastGpsPosition?.coords;
		return heading;
	}

	/**
	 * getLastKnownLocationDateTime
	 */
	getLastKnownLocationDateTime() {
		return _lastGpsPosition?.timestamp ? new Date(_lastGpsPosition.timestamp).toString() : null;
	}

	/**
	 * getLastKnownLocationLatitude
	 */
	getLastKnownLocationLatitude() {
		const { latitude } = _lastGpsPosition?.coords;
		return latitude;
	}

	/**
	 * getLastKnownLocationLongitude
	 */
	getLastKnownLocationLongitude() {
		const { longitude } = _lastGpsPosition?.coords;
		return longitude;
	}

	/**
	 * getLastKnownLocationProvider
	 */
	getLastKnownLocationProvider() {
		return _lastGpsPosition ? "geolocation" : "";
	}

	/**
	 * getLastKnownLocationSpeed
	 */
	getLastKnownLocationSpeed() {
		const { speed } = _lastGpsPosition?.coords;
		return speed;
	}
	getMaxSoundVolumen() {}
	getSoundVolumen() {}

	/**
	 * getView
	 * @param {XoneDataObject} DataObject
	 */
	getView(DataObject) {
		return getView(DataObject || _xoneDataObject);
	}

	/**
	 * Hide Group
	 * @param {string|number} groupId
	 */
	hideGroup(groupId) {
		_showGroupCallback ? _showGroupCallback(groupId.toString(), false, true) : console.error("hideGroup is not defined", groupId);
	}

	/**
	 * Hide loader spinner
	 */
	async hideLoader() {
		if (_hideLoaderCallback) await _hideLoaderCallback();
	}

	hideNavigationDrawer() {}
	hideSoftwareKeyboard() {}
	hideWaitDialog() {
		this.hideLoader();
	}
	injectJavascript(WebViewPropName, ScriptText) {}
	isApplicationInstalled(packageName) {}
	isOnCall() {}
	isSuperuserAvailable() {}
	isTaskKillerInstalled() {}
	isWifiConnected() {}
	isWifiEnabled() {}
	launchApp(PackageName, ExtrasParam) {}
	launchApplication(PackageName, ExtrasParam) {}

	/**
	 * Launch entry point
	 * @param {boolean} [isOnlyWebLayout]
	 */
	async launchEntryPoint(isOnlyWebLayout = false) {
		// Clear current DataObjects and Breadcrumbs
		AppDataHandler.clearBreadcrumbs();

		/**
		 * webLayout
		 * @type {string}
		 */
		const webLayout = xoneAppHandler.getWebLayout();

		if (webLayout) {
			// Push Web Layout
			await AppDataHandler.addNewXoneDataObject(webLayout, "WebLayout", true, true);
			await nextTick();
		}

		if (isOnlyWebLayout) return;

		/**
		 * entryPoint
		 * @type {string}
		 */
		const entryPoint = AppDataHandler.getAppData().getEntryPointCollection(AppDataHandler.getAppData().getCurrentVisualConditions());

		// Push Entry Point
		await AppDataHandler.addNewXoneDataObject(/*_entryPointFake || */ entryPoint, "EntryPoint", false, true);
	}

	lineFeed(Lines) {}
	lockGroup(groupId) {}
	/**
	 * login
	 * Login para appData.failWithMessage(-11888,"##LOGIN_START##")
	 * habrá que deprecarlo algún día pero se usa mucho
	 * @returns {Promise}
	 */
	login() {
		try {
			const userName = _xoneDataObject.getVariables("##LOGIN_NEWUSER##").split(",")[1];
			const password = _xoneDataObject.getVariables("##LOGIN_NEWPASS##").split(",")[1];
			return AppDataHandler.getAppData().login({
				userName,
				password,
			});
		} catch {
			return console.error("Login failed");
		}
	}
	makePhoneCall(PhoneNumber) {}

	/**
	 * Show MsgBox
	 * @param {string|XoneDataObject} message
	 * @param {string} [title]
	 * @param {number} [flags]
	 * @returns {Promise<number>}
	 */
	async msgBox(message, title = "", flags = 0) {
		let res = 0;

		if (message instanceof XoneDataObject) {
			if (_customMsgBoxCallback) return await _customMsgBoxCallback(message);
			else console.error("Method not implemented");
			return res;
		}

		if (_msgBoxCallback) {
			res = await _msgBoxCallback(message, title, flags);
		} else {
			console.error("msgBox not defined");
		}

		return res;
	}

	async msgBoxWithSound(Message, Title, type, Sound, Vibrate, NumberRepeat) {
		this.playSoundAndVibrate(Sound, Vibrate, NumberRepeat);
		return await this.msgBox(Message, Title, type);
	}

	/**
	 * Open new Edit View and push it in Breadcrumbs stack
	 * @param {XoneDataObject|string} param
	 */
	async openEditView(param) {
		// string: create XoneDataObject and push it into stack
		if (typeof param === "string") {
			await AppDataHandler.addNewXoneDataObject(param);
		}
		
		// XoneDataObject: push it into stack
		else if (param instanceof XoneDataObject) {
			/**
			 * xoneDataObject
			 * @type {XoneDataObject}
			 */
			const xoneDataObject = param;
			AppDataHandler.pushXoneDataObject(xoneDataObject);
		}
		// Bad call
		else {
			console.error("Bad call openEditView with param ", param);
		}
	}

	/**
	 * Open new Edit View and push it in Breadcrumbs stack then 1
	 * @param {XoneDataObject|string} param
	 */
	async openEditViewAndExit(param) {
		AppDataHandler.clearBreadcrumbs(true);
		await this.openEditView(param);
	}

	/**
	 * openFile
	 * @param {string} filePath
	 */
	openFile(filePath) {
		filePath = filePath.replace("/source/", "/"); // TODO: el filePath viene mal concateado, hay que quitar el source
		const element = document.createElement("a");
		element.setAttribute("href", filePath);
		element.setAttribute("target", "_blank");
		element.style.display = "none";
		document.body.appendChild(element);
		element.click();
		document.body.removeChild(element);
	}

	openMenu(Name, Mask, Mode) {}

	/**
	 * Open Url
	 * @param {string} Url
	 * @returns {void}
	 */
	openUrl(Url) {
		window.open(Url, "_blank");
		// if (_openUrlCallback) _openUrlCallback(Url);
		// else console.error("Method openUrl not implemented");
	}

	/**
	 * pickFile
	 * @param {string|object} PropName
	 * @param {string} Extensions
	 * @returns {Promise<string>}
	 */
	pickFile(PropName, Extensions,FileName) {
		return new Promise((resolve) => {
			const inputElement = document.createElement("input");
			inputElement.type = "file";
			inputElement.style.display = "none";

			const fileTypes = typeof PropName === "object" ? PropName.fileTypes : Extensions;
			if (fileTypes)
				inputElement.accept = fileTypes
					.split(",")
					.map((e) => "." + e)
					.join(",");

			inputElement.onchange = (e) => {
				const reader = new FileReader();
				reader.readAsDataURL(e.target.files[0]);
				reader.onload = () => {
					if (typeof PropName === "object") _xoneDataObject[PropName.targetProperty] = reader.result;
					else _xoneDataObject[PropName] = reader.result;
				};
				const file = e.target.files[0];
				
				let filename = '';
				if(FileName)
					filename = FileName + '.' + (file.name || `${generateUniqueId()}.png`).split('.').pop();
				else 
					filename =file.name || `${generateUniqueId()}.png`
			
				
				AppDataHandler.getAppData().UploadAsync(file,filename)
				//AppDataHandler.getAppData().DownloadAsync(filename)
					.then((fileName) => {
						if (fileName) {
							if (typeof PropName === "object") _xoneDataObject[PropName.targetProperty] = fileName;
							else _xoneDataObject[PropName] = fileName;
						}
					})
					.catch(() => {})
					.finally(() => {
						document.body.removeChild(inputElement);
						resolve(e.target.files[0]);
					});
			};

			document.body.appendChild(inputElement);
			inputElement.click();
		});
	}
/**
	 * pickFile
	 * @param {string|object} FileName
	 * @returns {Promise<string>}
	 */
downloadFile(FileName) {
	return new Promise((resolve) => {
		//FileName = 'Halloween-24-10-2024.png';
			AppDataHandler.getAppData().DownloadAsync(FileName)
			.then(resp => resp.status === 200 ? resp.blob() : Promise.reject('something went wrong'))
			.then(blob => {
			  const url = window.URL.createObjectURL(blob);
			  const a = document.createElement('a');
			  a.style.display = 'none';
			  a.href = url;
			  // the filename you want
			  a.download = FileName;
			  document.body.appendChild(a);
			  a.click();
			  window.URL.revokeObjectURL(url);
			})
			.catch();
	});
}

	/**
	 * playSound
	 * @param {string} sound
	 * @returns {Promise<void>}
	 */
	playSound(sound) {
		return this.playSoundAndVibrate(sound);
	}

	/**
	 * playSoundAndVibrate
	 * @param {string} Sounds
	 * @param {*} vibrate
	 * @param {*} NumberRepeat
	 * @param {*} ContinuePlaying
	 * @returns {Promise<void>}
	 */
	playSoundAndVibrate(Sounds, vibrate, NumberRepeat, ContinuePlaying) {
		return new Audio(`files/${Sounds}`).play();
	}

	playSoundVolumen(Number) {}
	print(Data) {}
	printBIDI(Size, Level, Data) {}
	printBarcode(Type, Data, Width, Height) {}
	printCommand(Data) {}
	printImage(Path, Width, Height, Align, Dither) {}
	printLine(Data) {}
	printPDF(Path, PageNumber) {}
	async printFrame(frameName) {
		// Si no tenemos html2canvas, lo cargamos a través de cdn
		if (!window.html2canvas) {
			const jsElement = document.createElement("script");
			jsElement.setAttribute("src", getAppPath() + "/modules/html2canvas/html2canvas.modified.min.js");
			// jsElement.setAttribute("src", "https://cdn.jsdelivr.net/npm/html2canvas@1.0.0-rc.5/dist/html2canvas.min.js");
			document.head.append(jsElement);
			while (!window.html2canvas) await new Promise((resolve) => setTimeout(() => resolve(), 1));
		}
		// Obtenemos el elemento a imprimir
		const element = document.querySelector(`.xone-frame[xone-name='${frameName}']`);
		// html2canvas no es compatible con box-shadow, así que lo remuevo del html
		var css = document.createElement("style");
		if (css.styleSheet) css.styleSheet.cssText = "* {box-shadow: unset !important}";
		else css.appendChild(document.createTextNode("* {box-shadow: unset !important}"));
		document.getElementsByTagName("head")[0].appendChild(css);
		// Creamos el canvas
		const canvas = await html2canvas(element);
		// Elimino el estilo que necesitaba una vez obtenido el canvas
		document.getElementsByTagName("head")[0].removeChild(css);
		// Creamos la ventana
		const newWindow = window.open("", "", "");
		newWindow.document.write(`<html><head></head><body><img src="${canvas.toDataURL()}" /></body></html>`);
		// Imprimimos
		setTimeout(() => newWindow.print(), 500);
	}

	/**
	 * quitApp
	 */
	quitApp() {
		this.exitApp();
	}

	/**
	 * recognizeSpeech
	 * @param {XoneDataObject|Object} DataObject
	 * @param {string} [PropName]
	 */
	recognizeSpeech(DataObject, PropName) {
		if (!webkitSpeechRecognition && !SpeechRecognition) return console.error("recognizeSpeech not supported. Use chrome browser.");
		let isXoneDataObject = false,
			isObject = false;
		// Is XoneDataObject
		if (DataObject instanceof XoneDataObject) {
			isXoneDataObject = true;
		}
		// Is Object with callback
		else if (typeof DataObject === "object" && DataObject.onRecognize) {
			isObject = true;
		} else return console.error("recognizeSpeech bad call");
		// Instance recognition
		/**
		 * recognition
		 * @type {SpeechRecognition}
		 */
		const recognition = new (webkitSpeechRecognition || SpeechRecognition)();
		// Params
		recognition.continuous = false;
		recognition.interimResults = false;
		recognition.maxAlternatives = 1;
		// On result
		recognition.onresult = (event) => {
			const result = event.results[event.results.length - 1][0].transcript;
			if (isObject) DataObject.onRecognize(result);
			if (isXoneDataObject) DataObject[PropName] = result;
		};
		// On speech end
		recognition.onspeechend = () => recognition.stop();
		// On stop
		recognition.onerror = (event) => {
			recognition.stop();
			if (isObject && DataObject.onError) DataObject.onError(event.error);
			console.log(
				`%c SpeechRecognition error: '${event.error}' %c Feature allowed in %c Chrome %c browser `,
				"color: red",
				"background: #4CABD5; color: white",
				"background: #1F3C6E; color: white",
				"background: #4CABD5; color: white"
			);
		};
		// start recognition
		recognition.start();
	}

	/**
	 * Refresh controls
	 * @param {Array} Props
	 */
	refresh(...Props) {
		const xoneView = getView(_xoneDataObject);
		if (!xoneView) return;
		const props = Props.length === 1 ? Props[0].toString().split(",") : Props;
		props.forEach((/** @type {string} */ e) => xoneView[e]?.refresh());
		if (Props.length === 0) xoneView.refresh();
	}
	/**
	 * refreshAll
	 */
	refreshAll() {
		this.refresh();
	}

	/**
	 * refreshContentRow
	 * @param {string} ContentName
	 * @param {number} Row
	 */
	refreshContentRow(ContentName, Row) {
		const xoneView = getView(_xoneDataObject);
		if (!xoneView) return;
		xoneView[ContentName]?.refreshRow(Row);
	}

	/**
	 * refreshContentSelectedRow
	 * @param {string} ContentName
	 */
	refreshContentSelectedRow(ContentName) {
		const xoneView = getView(_xoneDataObject);
		if (!xoneView) return;
		xoneView[ContentName]?.refreshSelectedRow();
	}

	/**
	 * refreshValue
	 * @param {string} Props
	 */
	refreshValue(Props) {
		const xoneView = getView(_xoneDataObject);
		if (!xoneView) return;
		xoneView[Props]?.refresh();
	}

	relayout() {
		this.refresh();
	}
	restartApp() {}
	returnToForeground() {}
	returnToMainMenu() {}
	saveDrawing(PropName, FileName) {}
	sendMail(To, Cc, Subject, Message, Attachments) {}
	sendSMS(Phone, Text) {}
	setFeedMode(FeedMode) {}
	setLanguage(Language) {}
	setMaxWaitDialog(Max) {}
	setNotificationLed(LedColor, LedOn, LedOff) {}
	setSelection(Prop, Position) {}
	shareData(Subject, Text, image) {}
	showConsoleReplica() {}
	showDatePicker(JSONObject) {}

	/**
	 * Show Group
	 * @param {string|number} groupId
	 * @param {*} [animationIn]
	 * @param {*} [animationInDuration]
	 * @param {*} [animationOut]
	 * @param {*} [animationOutDuration]
	 */
	showGroup(groupId, animationIn, animationInDuration, animationOut, animationOutDuration) {
		_showGroupCallback ? _showGroupCallback(groupId.toString()) : console.error("showGroup is  not defined", groupId);
	}

	/**
	 * Show Spinner loader
	 */
	async showLoader() {
		if (_showLoaderCallback) await _showLoaderCallback();
	}

	showNavigationDrawer(Orientation) {}

	/**
	 * showNotification
	 * @param {string} id
	 * @param {string} Title
	 * @param {string} Text
	 * @param {*} [TextStatusBar]
	 * @param {XoneDataObject | Function} [obj]
	 * @param {*} [NodeName]
	 */
	showNotification(id, Title, Text, TextStatusBar, obj, NodeName) {
		if (!("Notification" in window)) return console.error("shotNotification not supporter in your browse");

		/**
		 * donotification
		 */
		const doNotification = () => {
			if (Notification.permission !== "granted") return console.error("Not permission granted to show notifications");

			id = id.toString();

			// Dismiss if notification exists
			this.dismissNotification(id);

			// Create  new Notification
			const notification = new Notification(Title, {
				icon: "assets/manifest/72x72.png",
				body: Text,
			});

			// Add click event
			notification.onclick = () => {
				if (obj instanceof XoneDataObject) this.openEditView(obj);
				if (obj instanceof Function) obj();
				this.dismissNotification(id);
			};

			// Set notification to notifications map list
			_mapNotifications.set(id, notification);
		};

		// Check permission
		if (Notification.permission !== "granted") Notification.requestPermission().then(() => doNotification());
		// doNotification
		else doNotification();
	}

	/**
	 * Show Toast
	 * @param {SnackBarParams} params
	 */
	showSnackbar(params) {
		_showSnackbarCallback ? _showSnackbarCallback(params) : console.error("showSnackbar is  not defined", params);
	}

	showSoftwareKeyboard() {}

	/**
	 * Show Toast
	 * @param {string} message
	 */
	showToast(message) {
		_showToastCallback ? _showToastCallback(message) : console.error("showToast is not defined", message);
	}

	showWaitDialog(Text) {
		this.showLoader();
	}

	signDataObject(Data, Mask) {}

	/**
	 * sleep
	 * @param {number} Seconds
	 */
	async sleep(Seconds) {
		await new Promise((resolve) => setTimeout(() => resolve(), Number(Seconds) * 1000));
	}

	/**
	 * speak
	 * @param {string} Language
	 * @param {string} Text
	 */
	speak(Language, Text) {
		if (!speechSynthesis) return console.error("speak not supported in your browser");
		const speechSynthesisUtterance = new SpeechSynthesisUtterance(Text);
		if (Language) speechSynthesisUtterance.lang = Language;
		speechSynthesis.speak(speechSynthesisUtterance);
	}

	/**
	 * speakText
	 * @param {string} Text
	 */
	speakText(Text) {
		this.speak(null, Text);
	}

	startAudioRecord(NodeName, Prop, TimeOut) {}

	/**
	 * startCamera
	 * @param {string} PropName
	 * @param {string} type
	 */
	async startCamera(PropName, type = "photo") {
		let value;
		if (_startCameraCallback) value = await _startCameraCallback(type);
	}

	/**
	 * startGps
	 * @param {*} JSONObject
	 * @param {*} Interval
	 * @param {*} Flags
	 */
	async startGps(JSONObject, Interval = 30000, Flags) {
		if (!navigator.geolocation) return console.error("Geolocation not allowed");

		this.stopGps();

		const pos = await new Promise((resolve) =>
			navigator.geolocation.getCurrentPosition(
				(res) => resolve(res), // Error function
				() => {
					console.error("Could not get location");
					resolve();
				},
				{
					enableHighAccuracy: true,
					timeout: 5000,
					maximumAge: 0,
				}
			)
		);

		_lastGpsPosition = pos;

		console.log(` %c LOCATION `, "background-color: BLUE; color: white;", pos);

		this._startGpsTimeout = setTimeout(() => this.startGps(JSONObject, Interval, Flags), Interval);
	}

	/**
	 * startGpsV1
	 */
	async startGpsV1() {
		await this.startGps();
	}

	/**
	 * startGpsV2
	 * @param {*} JSONObject
	 * @param {*} Interval
	 * @param {*} Flags
	 */
	async startGpsV2(JSONObject, Interval, Flags) {
		await this.startGps();
	}

	startKioskMode() {}
	startPrint(PrinterType) {}
	startReplica() {}
	startScanner(NativeObject, Codes, Target) {}
	startSignature(Prop, Width, Height, BackgroundImage, ScreenOrientation) {}
	startWifi() {}
	stopAudioRecord() {}
	stopGps() {
		if (this._startGpsTimeout) clearTimeout(this._startGpsTimeout);
	}
	stopGpsV1() {}
	stopGpsV2() {}
	stopKioskMode() {}
	stopPlaySoundAndVibrate() {}
	stopReplica() {}
	stopWifi() {}

	/**
	 * Take photo
	 * @param {*} FileName
	 * @param {*} Width
	 * @param {*} Height
	 */
	takePhoto(FileName, Width, Height) {
		if (_startCameraCallback) _startCameraCallback();
	}

	/**
	 * Toogle Group
	 * @param {string|number} groupId
	 */
	toggleGroup(groupId) {
		_showGroupCallback ? _showGroupCallback(groupId.toString(), true) : console.error("toggleGroup is not defined", groupId);
	}

	uninstallApplication(packageName) {}
	unlockGroup(groupId) {}
	updateWaitDialog(message, value) {}

	/**
	 * @param {Blob} file
	 * @returns {Promise<string>}
	 */
	uploadFile(file) {
		const fileName = file.name || `${generateUniqueId()}.png`;
		const formData = new FormData();
		formData.append("file", file, fileName);
		return fetch("upload", {
			//"http://localhost:8000/upload", {
			method: "POST",
			headers: { processData: false, contentType: false },
			body: formData,
		})
			.then((res) => {
				if (!res.status.toString().startsWith("2")) return null;
				res.hasError ? "" : fileName;
			})
			.catch(() => null);
	}

	useLastPrinter(True) {}
	vibrate() {}
	writeString() {}
}

const xoneUI = new XoneUI();

export default xoneUI;
