import IConnection from "../../Interfaces/IConnection";
import IFieldProperties from "../../Interfaces/IField";
import IResultSet from "../../Interfaces/IResultSet";
import IXoneApp from "../../Interfaces/IXoneApp";
import { SqlParser } from "../../Parsers/SQL/SqlParser";
import { Guid } from "../../Utils/Guid";
import XoneTestResulset from "../Test/XoneTestResulset";
import XoneConnectionData from "../XoneConnectionData";
import XoneWebCoreConnection from "./XoneWebCoreConnection";
import XoneWebCoreResulset from "./XoneWebCoreResulset";

export default class XoneWebCoreConnectionData extends XoneConnectionData {
    private m_context: any;
    private m_nSessionIdCounter: any;
    private m_nLastInsertedId: any;
    private m_nLastRecordsAffected: any;
    private m_strLastInsertedTable: any;
    private m_stmt: {};
    m_connection: XoneWebCoreConnection;
    /**
     * Crea una conexión a base de datos
     *
     * @param Name  Nombre de la conexión.
     * @param Owner Aplicación a la cual pertenece esta conexión.
     */
    constructor(context: any, Name: string, Owner: IXoneApp) {
        super(Name, Owner);
        this.m_context = context;
        // K11101001: Utilizar SESSIONID numérico para mejorar el rendimiento de la maquinaria.
        // this.m_nSessionIdCounter = new AtomicLong(0);
        // // A14011501: Optimizaciones para obtener claves numéricas de conexiones sqlite y demás.
        // this.m_nLastInsertedId = new ThreadLocal<>();
        // this.m_nLastRecordsAffected = new ThreadLocal<>();
        // this.m_strLastInsertedTable = new ThreadLocal<>();
        // // TODO ADD TAG
        // // Nos creamos el holder para el statement...
        // this.m_stmt = {}; // new ThreadLocal<>();
    }

    /**
     * TRUE si el DBMS soporta la sintaxis TOP x
     *
     * @return Esta función posiblemente no se redefina en esta plataforma.
     */
    public TopAllowed(): boolean {
        return false;
    }

    /**
     * TRUE si el DBMS soporta la sintaxis LIMIT x
     *
     * @return Mirar arriba plis.
     */
    public LimitAllowed(): boolean {
        return false;
    }

    /**
     * TRUE si la conexión tiene caracteres para escapear cadenas de caracteres.
     *
     * @return Devuelve TRUE si hay caracteres en la lista para sustituir.
     */
    public HasEscapeChars(): boolean {
        return false;
    }

    /**
     * A11092601: Modificaciones para mejorar el soporte de conexiones remotas y demás.
     * Devuelve TRUE si la conexión acepta sentencias SQL parseadas para su ejecución o para queries.
     *
     * @return
     */
    public acceptsParsedSentences(): boolean {
        return true;
    }

    public acceptsEmptyQueries(): boolean {
        return false;
    }

    /**
     * A11092601: Modificaciones para mejorar el soporte de conexiones remotas y demás.
     * Devuelve TRUE si la conexión permite recuperar un ID autonumérico dado un ROWID.
     *
     * @return
     */
    public retrievesAutonumericKeys(): boolean {
        return false;
    }


    /**
     * Obtiene una conexión física a la fuente de datos que encapsula esta clase. Es de obligatoria redefinición por los derivados de esta clase.
     *
     * @return Devuelve una nueva conexión de datos (o la misma si solo se emplea una)
     * @throws Exception
     */
    public GetNewConnection(ReadOnly: boolean = false): IConnection {
        this.m_connection = new XoneWebCoreConnection(this.m_strConnString);
        this.setRowIdFieldName(this.m_connection.getRowIdFieldName());
        this.setDatemask(this.m_connection.getDatemask());
        return this.m_connection;
    }

    public GetCurrentConnection(ReadOnly: boolean = false): IConnection {
        if (this.m_connection)
            return this.m_connection;
        return this.GetNewConnection(ReadOnly);
    }

    /**
     * Crea un conjunto de datos sin conexión original. Para ello crea una local y la devuelve como
     * parte del conjunto que la cierra cuando lo cierran a él
     *
     * @param Sentence Sentencia SQL que se ejecuta para obtener el conjunto de datos.
     * @return Devuelve un conjunto de datos o NULL si algo falla.
     * @throws Exception
     */
    /// public CreateRecordset(Sentence:string): IResultSet {
    //     return this.CreateRecordset(null, Sentence);
    // }

    /**
     * A11092601: Modificaciones para mejorar el soporte de conexiones remotas y demás.
     * Ejecuta una sentencia SQL parseada que devuelve datos.
     *
     * @param Sentence
     * @return
     * @throws Exception
     */
    /// public CreateRecordset(Sentence:any): IResultSet{
    //     return this.CreateRecordset(null, Sentence);
    // }

    /**
     * Crea un objeto de acceso a datos (recordset) para leer los datos de un objeto
     *
     * @param Connection Conexión que se quiere usar para abrir la fuente de datos. NULL para crear una local y usarla solamente para esta función.
     * @param Sentence   Setencia SQL (SELECT) que se quiere ejecutar para abrir la fuente de datos.
     * @param MaxRows    Máximo de registros para no usar el que tiene por defecto la clase.
     * @return Devuelve el conjunto de datos creado o NULL si algo falla.
     * @throws Exception
     */
    /// public abstract CreateRecordset(Connection Connection, String Sentence, int MaxRows): IResultSet;

    /**
     * A11092601: Modificaciones para mejorar el soporte de conexiones remotas y demás.
     * Esta implementación permite la ejecución de sentencias SQL parseadas con anterioridad.
     *
     * @param Connection
     * @param Sentence
     * @param MaxRows
     * @return
     * @throws Exception
     */
    /// public abstract ResultSet CreateRecordset(Connection: IConnection | string, ...args?:any) throws Exception;

    /*
     * TODO ADD TAG 17042014: Luis Un recordset a partir de la clase de parametros
     */
    public CreateRecordset(Connection: IConnection | string, ...args: any): IResultSet {
        return new XoneTestResulset(Connection as string);
        // if (typeof Connection == 'string' ) {
        //     return this.GetCurrentConnection().executeQuery(Connection as string);
        // }
        // return null;
    }

    /*
     * TODO ADD TAG 17042014: Luis Un recordset a partir de la clase de parametros
     */
    public async CreateRecordsetAsync(Connection: IConnection | string, ...args: any): Promise<IResultSet> {
        if (typeof Connection == 'string') {
            return await this.GetCurrentConnection().executeQueryAsync(Connection as string, ...args);
        }
        return null;
    }

    /*
     * TODO ADD TAG 17042014: Luis Un recordset a partir de la clase de parametros
     */
    public async CountAsync(Connection: IConnection | string, ...args: any): Promise<IResultSet> {
        if (typeof Connection == 'string') {
            return await this.GetCurrentConnection().countAsync(Connection as string, ...args);
        }
        return null;
    }

    /**
     * Crea un objeto de acceso a datos (recordset) para leer los datos de un objeto
     *
     * @param Connection Conexión que se quiere usar para abrir la fuente de datos. NULL para crear una local y usarla solamente para esta función.
     * @param Sentence   Setencia SQL (SELECT) que se quiere ejecutar para abrir la fuente de datos.
     * @return Devuelve el conjunto de datos creado o NULL si algo falla.
     * @throws Exception
     */
    //public abstract ResultSet CreateRecordset(Connection Connection, String Sentence) throws Exception;

    /**
     * A11092601: Modificaciones para mejorar el soporte de conexiones remotas y demás.
     * Ejecuta una sentencia SQL parseada que devuelve datos.
     *
     * @param conn
     * @param Sentence
     * @return
     * @throws Exception
     */
    /// public abstract ResultSet CreateRecordset(Connection conn, SqlParser Sentence) throws Exception;

    /**
     * Ejecuta una sentencia SQL dentro de la conexión que manipula este objeto. No lleva conexión física como parámetro porque crea una temporal, trabaja con ella y la cierra.
     * <p>
     * K11092601: ExecuteSqlString y similares deben devolver un objeto, que puede ser ResultSet.
     * Cambiar el tipo de resultado.
     *
     * @param Sentence Sentencia SQL que se quiere ejecutar.
     * @return Devuelve TRUE si la ejecución de la sentencia SQL es correcta.
     * @throws Exception
     */
    // public ExecuteSqlString(Sentence: string): any {
    //     return ExecuteSqlString(null, Sentence, true);
    // }

    /**
     * A10090601:	Incluir el mecanismo de réplica selectiva por colecciones a la maquinaria.
     * Ejecuta un SQL sin replicar
     * <p>
     * K11092601: ExecuteSqlString y similares deben devolver un objeto, que puede ser ResultSet.
     * Cambiar el tipo de resultado.
     */
    // public Object ExecuteLocalSqlString(String Sentence) throws Exception {
    //     return ExecuteSqlString(null, Sentence, false);
    // }

    /**
     * A11092601: Modificaciones para mejorar el soporte de conexiones remotas y demás.
     * Ejecuta una sentencia SQL parseada con réplica.
     * <p>
     * K11092601: ExecuteSqlString y similares deben devolver un objeto, que puede ser ResultSet.
     * Cambiar el tipo de resultado.
     *
     * @param Sentence
     * @return
     * @throws Exception
     */
    // public Object ExecuteParsedSql(SqlParser Sentence) throws Exception {
    //     return ExecuteParsedSql(null, Sentence, true);
    // }

    /**
     * Ejecuta una sentencia SQL dentro de la conexión que manipula este objeto.
     * A10090601:	Incluir el mecanismo de réplica selectiva por colecciones a la maquinaria.
     * Un parámetro para decir si replica.
     * <p>
     * K11092601: ExecuteSqlString y similares deben devolver un objeto, que puede ser ResultSet.
     * Cambiar el tipo de resultado
     *
     * @param Connection Conexión de datos que se quiere usar para ejecutar la sentencia. NULL para crear una temporal, ejecutar la sentencia y cerrarla.
     * @param Sentence   Sentencia SQL que se va a ejecutar.
     * @param Replicate  TRUE si replica.
     * @return Devuelve TRUE si la sentencia se ejecuta correctamente.
     * @throws SQLException
     * @throws Exception
     */
    public ExecuteSqlString(Connection: IConnection | string, Sentence?: SqlParser | string, Replicate?: boolean, ...args): any {
        return 0;
    }

    public async ExecuteSqlStringAsync(Connection: IConnection | string, Sentence?: SqlParser | string, Replicate?: boolean, ...args): Promise<any> {
        return await this.GetCurrentConnection().executeAsync(Sentence, 100, ...args);
    }

    
    public async UploadAsync( File?: Blob, FileName?: string): Promise<any> {
        if (!this.m_connection){
            this.m_connection = new XoneWebCoreConnection(this.m_strConnString);
            this.setRowIdFieldName(this.m_connection.getRowIdFieldName());
            this.setDatemask(this.m_connection.getDatemask());
        }
        return await this.m_connection.uploadAsync(File, FileName);
    }
    public async DownloadAsync( FileName?: string): Promise<any> {
        if (!this.m_connection){
            this.m_connection = new XoneWebCoreConnection(this.m_strConnString);
            this.setRowIdFieldName(this.m_connection.getRowIdFieldName());
            this.setDatemask(this.m_connection.getDatemask());
        }
        return await this.m_connection.downloadAsync(FileName);
    }

    /**
     * A11092601: Modificaciones para mejorar el soporte de conexiones remotas y demás.
     * Esta implementación opcional permite ejecutar una sentencia SQL parseada en vez de una cadena SQL.
     * <p>
     * K11092601: ExecuteSqlString y similares deben devolver un objeto, que puede ser ResultSet.
     * Cambiar el tipo de resultado.
     *
     * @param Connection
     * @param Sentence
     * @param Replicate
     * @return
     * @throws SQLException
     * @throws Exception
     */
    public ExecuteParsedSql(Connection: IConnection | string, Sentence?: SqlParser | string, Replicate?: boolean, ...args): any {
        return 0;
    }

    public async ExecuteParsedSqlAsync(Connection: IConnection | string, Sentence?: SqlParser | string, Replicate?: boolean, ...args): Promise<any> {
        return await this.GetCurrentConnection().executeAsync(Sentence, 100, ...args);
    }



    /**
     * Reemplaza el valor del operador específico dentro de la cadena SQL que se pasa como parámetro.
     *
     * @param Collection Colección con la que se está trabajando.
     * @param Sentence   Sentencia SQL o cadena en la cual se quiere sustituir el operador.
     * @param OperName   Nombre del operador que se quiere sustituir.
     * @return Devuelve la sentencia original con el operador sustituido.
     * @throws Exception
     */
    public ReplaceCustomOper(Collection: IFieldProperties, Sentence: string, OperName: string): string {
        return Sentence;
    }

    /**
     * Inserta una sentencia TOP dentro del SQL que se pasa como parámetro. Colocada aquí para mantener compatibilidad de interfaces
     * pero no se implementará en esta plataforma.
     *
     * @param Sentence Sentencia SQL que se quiere modificar.
     * @param Rows     Cantidad de filas que se quiere limitar en el TOP x o LIMIT x
     * @return La misma sentencia pasada como parámetro.
     */
    public InsertTop(Sentence: string, Rows: number): string {
        return Sentence;
    }

    /**
     * Dado un nombre de campo lo formatea usando los caracteres de inicio y fin de campo.
     *
     * @param FieldName Nombre del campo a formatear.
     * @return Devuelve el nombre del campo encerrado en sus secuencias de inicio y fin de campo.
     */
    public QuoteFieldName(FieldName: string): string {
        return FieldName;
    }

    /**
     * Dado un nombre de tabla u otro tipo de objeto de base de datos, lo devuelve formateado con su prefijo si este está definido.
     *
     * @param ObjectName Nombre de tabla u objeto en general de la base de datos para arreglar con el prefijo.
     * @return Devuelve el nombre de objeto ya desarrollado con su prefijo si aplica.
     */
    // public String FixObjectName(String ObjectName):string {
    //     return FixObjectName(ObjectName, null);
    // }



    /**
     * Dada una cadena sustituye los caracteres para los cuales se hayan definido secuencias de escape por dichas secuencias.
     *
     * @param Value Cadena en la cual se van a sustituir los caracteres por secuencias de escape.
     * @return Devuelve la cadena original con los caracteres sustituidos por sus secuencias de escape.
     */
    public EscapeString(Value: string): string {
        if (Value.startsWith('\''))
            return Value;
        return `\'${Value}\'`;
    }

    /**
     * Genera un ROWID para la conexión actual. Emplea los criterios de número de tabla, MID, DBID, etc.
     *
     * @param TableName Nombre de la tabla para la cual se quiere generar el ROWID.
     * @return Devuelve un ROWID respetando los valores de réplica y el tamaño del campo ROWID.
     */
    //public GenerateRowId(TableName:string):string {return Guid.create().toString();}

    /**
     * Desarrolla el valor de un objeto y lo devuelve como cadena lista para insertar en un SQL o un filtro
     *
     * @param Value Valor que se quiere desarrollar. Se usará la máscara de fecha de la conexión.
     * @return CAdena representando el valor del objeto para escribir en una sentencia SQL o un filtro de datos.
     */
    // public DevelopObjectValue(Value:any):string {
    //     return DevelopObjectValue(Value, true);
    // }

    /**
     * Esta función se emplea para cerrar conexiones locales si es que las tiene. No necesariamente tiene por qué
     * usarse para terminar la clase, simplemente cuando se quiera cerrar o destruir los objetos locales.
     */
    public Terminate(): void { }

    /**
     * Si el DBMS soporta left outer joins
     */
    public IsOuterJoinSupported(): boolean { return false; }

    /**
     * A11092601: Modificaciones para mejorar el soporte de conexiones remotas y demás.
     * Devuelve una clave numérica dada una tabla y un ROWID. Permite abstraer la obtención de la clave autonumérica
     * en conexiones que no son offline.
     *
     * @param TableName
     * @param RowIdFieldName
     * @return
     * @throws Exception
     */
    public RetrieveNumericKey(TableName: string, KeyName: string, RowIdFieldName: string, RowId: string): number { return 0; }

    /**
     * A11092601: Modificaciones para mejorar el soporte de conexiones remotas y demás.
     * Esta función permite calcular si un ROWID es único dentro de la conexión de datos.
     *
     * @param tableName
     * @param RowID
     * @return
     * @throws Exception
     */
    public isUniqueRowID(tableName: string, RowID: string): boolean { return true; }

    // A11120701: Mecanismo para exponer el DBMS de una conexión.
    // Expone el tipo de DBMS que tiene debajo esta conexión. Obligatorio implementar.
    public getDbmsTag(): string { return "OnlineWebCore" };
}